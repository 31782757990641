import React from 'react'
import { Box, Heading, css } from 'theme-ui'
import { DiCode } from 'react-icons/di'
import { DiRuby } from 'react-icons/di'
import { SiFlutter } from 'react-icons/si'
import { FaReact } from 'react-icons/fa'
import Ruby from "./Ruby";
import Flutter from "./Flutter";
import ReactImage from "./ReactImage";

const styles = {
  wrapper: {
    display: `flex`,
    bg: `omegaLighter`,
    position: `relative`,
    zIndex: 2,
    p: 3,
  },
  icon: {
    display: `flex`,
    alignItems: `center`,
    mb: 0,
    mx: 3,
    svg: {
      color: `omegaDark`,
      fontSize: 7,
    },
  },
  number: {
    textAlign: `center`,
    fontWeight: `bold`,
    color: `alphaDark`,
    mx: 3,
    mb: 0,
  },
  text: {
    color: `omegaDark`,
    mb: 0,
  },
}
const Stats = () => {
  return (
    <Box sx={styles.wrapper}>
      <Heading variant='h3' sx={styles.icon}>
        <DiCode />
      </Heading>
      <Heading variant='h2' sx={styles.number}>
        <DiRuby />
        <Heading variant='h4' sx={styles.text}>
          RUBY
        </Heading>
      </Heading>
      <Heading variant='h2' sx={styles.number}>
        <SiFlutter />
        <Heading variant='h4' sx={styles.text}>
          FLUTTER
        </Heading>
      </Heading>
      <Heading variant='h2' sx={styles.number}>
        <FaReact />
        <Heading variant='h4' sx={styles.text}>
          REACT
        </Heading>
      </Heading>
    </Box>
  )
}

export default Stats

