import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Testimonial from '@widgets/Testimonial'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Hero from '../components/Hero/Hero'

const Posts = ({ data: { posts = {}, featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props


  posts.group.sort((a,b) => {
    if (a.categoryName === 'Artykuły') return 1
    if (a.categoryName === 'Kursy') return 1
    if (a.categoryName === 'Ebooki') return -1
    if (a.categoryName === 'Gościnne Podcasty') return -1
    if (a.categoryName === 'Wystąpienia Publiczne') return -1
    return 0
  })

  console.table(posts.group.map((group) => group.categoryName))

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      {/*<Stack>*/}
      {/*  <Main>*/}
      {/*    <CardList*/}
      {/*      nodes={featuredPosts.nodes}*/}
      {/*      limit={5}*/}
      {/*      variant='vertical-cover'*/}
      {/*      title='Zobacz'*/}
      {/*      slider*/}
      {/*      columns={[1, 2, 3, 3]}*/}
      {/*      autoPlay*/}
      {/*    />*/}
      {/*  </Main>*/}
      {/*</Stack>*/}
      <Stack>
        <Main>
          {posts.group.sort((a,b) => a.name === 'Wystąpienia' ).map(group => (
            <>
              <Divider />
              <CardList
                nodes={group.nodes}
                limit={4}
                variant='horizontal-md'
                title={group.categoryName}
                key={group.categoryName}
                columns={[1, 2, 2, 2]}
                withTitleLink
              />
            </>
          ))}
        </Main>
      </Stack>
      <Stack>
        <Main>
          <Testimonial />
        </Main>
      </Stack>
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
